import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

const StandardPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Privacy Policy" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">

          <h2>Simple Summers LLC</h2>
          <h2>Privacy Policy</h2>

          <p className="font-italic">Date Privacy Policy Last Modified: March 18th, 2019</p>

          <h4>INTRODUCTION</h4>

          <p>Simple Summers, LLC (“Company” or “We”) respects your privacy and are committed to protecting your personal data through our compliance with this Privacy Policy.</p>

          <p>This Policy will inform you as to how we look after your personal data, your privacy rights, how the law protects you, and our practices for collecting, using, maintaining, protecting and disclosing your personal data when you visit our website [simplesummers.com] (our “Website”) or use any of the Company’s services (including, without limitation, any desktop or mobile applications, products or other services) (collectively, the Website and services are referred to as the “Services”) </p>

          <p>This Policy is provided in a layered format so you can click through the specific areas set out below. Alternatively, you can view a full version of the Privacy Policy here. </p>

          <p>Please read this Policy carefully to understand our policies and practices regarding your personal data and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Services because we will not be able to perform the contract we have or are trying to enter with you (for example, to provide you with Services). </p>

          <p>By accessing or using our Services, you agree to this Privacy Policy, and if you are under age 18, you have your parent or legal guardian’s permission to use our Services and your parent/legal guardian has agreed to this Privacy Policy.</p>

          <ul className="list-unstyled">
              <li>
                  <a href="/privacy/#important-information-and-who-we-are">IMPORTANT INFORMATION AND WHO WE ARE</a>
              </li>
              <li>
                  <a href="/privacy/#the-data-we-collect-about-you">THE DATA WE COLLECT ABOUT YOU</a>
              </li>
              <li>
                  <a href="/privacy/#how-is-your-personal-data-collected">HOW IS YOUR PERSONAL DATA COLLECTED</a>
              </li>
              <li>
                  <a href="/privacy/#how-we-use-your-personal-data">HOW WE USE YOUR PERSONAL DATA</a>
              </li>
              <li>
                  <a href="/privacy/#disclosures-of-your-personal-data">DISCLOSURES OF YOUR PERSONAL DATA</a>
              </li>
              <li>
                  <a href="/privacy/#children-and-privacy">CHILDREN AND PRIVACY</a>
              </li>
              <li>
                  <a href="/privacy/#international-transfers">INTERNATIONAL TRANSFERS</a>
              </li>
              <li>
                  <a href="/privacy/#data-security">DATA SECURITY</a>
              </li>
              <li>
                  <a href="/privacy/#data-retention">DATA RETENTION</a>
              </li>
              <li>
                  <a href="/privacy/#your-legal-rights">YOUR LEGAL RIGHTS</a>
              </li>
              <li>
                  <a href="/privacy/#contact-information">CONTACT INFORMATION</a>
              </li>
          </ul>

          <h4 name="important-information-and-who-we-are">IMPORTANT INFORMATION AND WHO WE ARE</h4>

          <h6>PURPOSE OF THIS PRIVACY NOTICE</h6>

          <p>This Privacy Policy aims to give you information on how Simple Summers, LLC collects and processes your personal data through your use of the Services, including any data you may provide through the Services when you sign up for an account or interact with any of our favoriting functionality.</p>

          <h6>CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES</h6>

          <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you through a notice or banner on the Website home page or by sending an email to the email address you provided to us. </p>

          <p>You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Services and this privacy policy to check for any changes.</p>

          <p>The date the privacy policy was last revised is identified at the top of this page.</p>

          <p>It is important that the personal information, and importantly, the contact information we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

          <h6>THIRD PARTY LINKS</h6>

          <p>The Services may include links (and advertisements) to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third party websites and are not responsible for their privacy statements/notices. When you leave our Services (including the Website), we encourage you to read the privacy notice of every website you visit and application you use.</p>

          <h4 name="the-data-we-collect-about-you">THE DATA WE COLLECT ABOUT YOU</h4>

          <p>“Personal Data” (or “personal information”) means any information about an individual from which that person can be identified. It does not include data where the person’s identity has been removed.</p>

          <p>This Policy applies to Personal Data we collect:</p>

          <p>On this Website;</p>

          <p>In email, text and other electronic messages between you and this Website;</p>

          <p>Through the Company’s mobile and/or desktop applications you download from this Website, through the Company’s mobile and/or desktop applications that are available for download from other third-party sources, or through such applications made available for your use through an authorized third party which provide dedicated non-browser-based interaction between you and the Company and/or this Website; and</p>

          <p>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</p>

          <p>We collect several types of Personal Data from and about users of our Services, including the following:</p>

          <p>Contact Data includes: email address, phone number.</p>

          <p>Technical Data includes: internet protocol (IP) addresses, your login data, your internet connection, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and information about the equipment (computer or mobile device) you use to access our Services.</p>

          <p>Profile Data includes: details specific to the user’s profile/account, including favorited site content.</p>

          <p>Usage Data includes: information about how you use our Services.</p>

          <p>Marketing and Communications Data includes: email address, first name, last name, your preferences in receiving marketing from us and any third parties, and your communication preferences.</p>

          <p>We also collect, use, and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your Personal Data but is not considered Personal Data because this data does not directly or indirectly reveal your or any minor’s identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific Service, or the number of minors being registered. However, if we combine or connect Aggregated Data with your information so that it can directly or indirectly identify you, we will treat the combined data as Personal Data which will be used in accordance with this Privacy Policy.</p>

          <p>We do collect Technical Data. As you navigate through and interact with our Services, we may use automatic data collection technologies to collect certain Technical Data about your equipment, browsing actions and patterns. We do not use automated technologies to collect information about your online activities over time and across third-party websites or other online services.</p>

          <p>We do not maintain or associate the Technical Data we collect with your Personal Data. The Technical Data helps us to improve our Services and to deliver a better and more personalized service, including by enabling us to:</p>

          <p>Estimate our audience size and usage patterns.</p>

          <p>Store information about your preferences, allowing us to customize our Services according to your individual interests.</p>

          <p>Speed up your searches.</p>

          <p>Recognize you when you return to our Services.</p>

          <p>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, or genetic and biometric data). Nor do we collect any information about criminal convictions or offenses.</p>

          <h4 name="how-is-your-personal-data-collected">HOW IS YOUR PERSONAL DATA COLLECTED</h4>

          <p>We use different methods to collect this information from you and about you including:</p>

          <p>Direct interactions. You may give us your Personal Data by filling in forms or by corresponding with us by mail, phone, email or otherwise. This includes Personal Data you provide when you:</p>

          <p>Register and use our Services</p>

          <p>Create an account on our Website or Services;</p>

          <p>Subscribe to our Services;</p>

          <p>Request marketing be sent to you; or</p>

          <p>Give us some feedback or report a problem with our Services.</p>

          <p>Automated technologies or interactions. As you interact with our Website and/or Services, we may automatically collect Technical Data about your equipment, browsing actions and patterns as you navigate through the Services. Information collected automatically may include usage details, IP addresses and information collected through cookies.</p>

          <p>Third parties or publicly available sources. We may receive Personal Data about you from various third parties and public sources as set out below:</p>

          <p>Technical Data from the following parties:</p>

          <p>Analytics providers such as Google or Facebook;</p>

          <p>Search information providers such as Google.</p>

          <p>The Website does not have the functionality for users to submit contributions directly (e.g., a forum), but authorized users may provide information to be published or displayed (hereinafter, “posted”) on public areas of the Services, or transmitted to other users of the Services or third parties, such as the camps operated by organizations on our platform (collectively, “Organization Contributions”). Organization Contributions will be posted on and transmitted to other users at your own risk. Please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your Organization Contributions. Therefore, we cannot and do not guarantee that your Organization Contributions will not be viewed by unauthorized persons.</p>

          <p>The technologies we use for this automatic data collection of Technical Data may include Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</p>

          <h6>THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES.</h6>

          <p>Some content or applications, including advertisements, on our Services are served by third-parties, including advertisers, ad networks and servers, content providers and application providers. These third parties may use cookies or other tracking technologies to collect information about you when you use our Services. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>

          <p>We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>

          <h4 name="how-we-use-your-personal-data">HOW WE USE YOUR PERSONAL DATA</h4>

          <p>As permitted by law, we use information that we collect about you or that you provide to us, including your Personal Data:</p>

          <p>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including without limitation to: provide our Services and content, and any information you request from us, to you; allow you to participate in interactive features of our Services; for billing and collection purposes, provide notices to you about your account and/or subscription, including expiration and renewal notices; provide notices to you about changes to our Services or any products or services we offer; and to provide notices to you of updates to the Privacy Policy or other terms of service.</p>

          <p>Where we need to comply with legal or regulatory obligations.</p>

          <p>We generally do not rely on consent as the legal basis for processing your Personal Data other than in relation to sending third party or direct marketing communications to you via email about our own and third-parties’ goods and services that may be of interest to you. You have the right to withdraw consent to our marketing communications by emailing the address provided in the Contact Information section below or unsubscribing via the link at the bottom of any email provided by us.</p>

          <p>We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>

          <h6>MARKETING</h6>

          <p>We strive to provide you with choices regarding certain uses of your Personal Data, particularly around marketing and advertising.</p>

          <h6>PROMOTIONAL OFFERS FROM THE COMPANY</h6>

          <p>We may use your Identity, Contact, Usage, and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services, and offers may be relevant for you. We call this marketing. You will receive marketing communications from us if you have requested information from us or purchased Services from us, and in each case, if you have not opted out of receiving that marketing.</p>

          <h6>THIRD-PARTY MARKETING</h6>

          <p>We will get your express opt-in consent before we share your Personal Data with any company outside of [Name of Organization] for marketing purposes.</p>

          <h6>OPTING OUT</h6>

          <p>You can request that we or third parties to stop sending you marketing messages at any time by contacting us at the email address provided in the Contact Information section below.</p>

          <h6>COOKIES</h6>

          <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this Website may become inaccessible or not function properly.</p>

          <h6>CHANGE OF PURPOSE</h6>

          <p>We will only use your Personal Data for the purposes for which we collected it, unless we reasonably determine that we need to use it for another reason compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us at the email address provided in the Contact Information section below.</p>

          <p>If we need to use your Personal Data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>

          <p>Please note that we may process your Personal Data without your knowledge or consent, in compliance with the above rules, where such processing is required or permitted by law.</p>

          <h4 name="disclosures-of-your-personal-data">DISCLOSURES OF YOUR PERSONAL DATA</h4>

          <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>

          <p>We may disclose Personal Data that we collect or you provide as described in this Policy with the parties described below for the purposes set forth in the table in Section 4 above:</p>

          <h6>Internal Third Parties:</h6>

          <p>To our subsidiaries and affiliates.</p>

          <h6>External Third Parties:</h6>

          <p>To contractors, service providers and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</p>

          <p>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by the Company about our Services’ users is among the assets transferred.</p>

          <p>To third parties to market their products or services to you if you have consented to or not opted out of these disclosures.</p>

          <h4 name="children-and-privacy">CHILDREN AND PRIVACY</h4>

          <p>The Services are intended for users who are parents/legal guardians of minors, and is not intended for users under age 13. As such, the Company only collects information directly provided by users who are over age 18 (which may include information about a minor). The Company does not collect information directly from minors under age 13. The information provided by users about minors is only used [to register the child for an event/camp OR for the purpose for which it was provided] and will not be shared with any third party providers except as specified in this privacy policy.</p>

          <h4 name="international-transfers">INTERNATIONAL TRANSFERS</h4>

          <p>If you are located in a province, country, or other government jurisdiction outside of the United States, and if you choose to provide your personal information to the Company through our Services, please be aware that your personal information will be transferred to and maintained on computers located in the United States and the applicable US privacy laws may not be as protective as those in your jurisdiction.</p>

          <h4 name="data-security">DATA SECURITY</h4>

          <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration and disclosure. We also limit access to Personal Data to those employees, agents, contractors, and other third parties who have a business need to know. These individuals will only process your Personal Data on our instructions and they are subject to a duty of confidentiality.</p>

          <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to or through our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Services.</p>

          <p>We have put in place procedures to deal with any suspected incident involving Personal Data and will notify you and any applicable regulator of an incident involving Personal Data where we are legally required to do so.</p>

          <h4 name="data-retention">DATA RETENTION</h4>

          <p>We maintain one or more databases to store your Personal Data and may keep such information indefinitely in order to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. In some circumstances you may ask us to delete your Personal Data. See the section below regarding Requesting Erasure for more information. In some circumstances we may anonymize your Personal Data so that it can no longer be associated with you for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

          <h4 name="your-legal-rights">YOUR LEGAL RIGHTS</h4>

          <h6>CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</h6>

          <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>

          <p>Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</p>

          <p>Promotional Offers from the Company. If you do not wish to have your email address used by the Company to promote our own or third parties’ products or services, you can opt-out by sending us an email stating your request to the email address provided below in the Contact Information section. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to communications with the Company as a result of a product or subscription purchase, product service experience or other transactions.</p>

          <p>We do not control third parties’ collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (”NAI”) on the NAI’s website.</p>

          <h6>YOUR CALIFORNIA PRIVACY RIGHTS</h6>

          <p>California Civil Code Section § 1798.83 permits users of our Services that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please email us at the address listed in the Contact Information section below.</p>

          <h4 name="contact-information">CONTACT INFORMATION</h4>

          <p>To ask questions or comment about this Policy and our privacy practices, please reach us by email at hello@simplesummers.com.</p>

        </div>
      </div>
    </div>

  </Layout>
)

export default StandardPage
